<template>
	<div>
		<app-back-header></app-back-header>
		<v-row no-gutters dense class="my-card d-flex flex-column flex-grow-0">
			<div class="pa-0" style="height:58px">
				<v-row no-gutters dense class="d-flex flex-grow-0">
					<v-col cols="5" class="pa-0">
						<p class="my-heading">
							Deliveries
							<span class="heading-sf16">of {{ titleName }}</span>
						</p>
					</v-col>
					<v-col cols="6" class="pa-0 filter-search">
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Search"
							single-line
							dense
							color="#CACEDA"
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="1" class="export-icon">
						<div
								v-on:click="deliveryExport()"
								style="cursor: pointer;"
							>
								<v-tooltip left color="#757575">
									<template v-slot:activator="{ on }">
										<img
											v-on="on"
											:src="$store.state.icons.icons['file-export']"
											style="height:24px;filter: invert(61%) sepia(10%) saturate(1008%) hue-rotate(178deg) brightness(84%) contrast(84%);"
											alt
										/>
									</template>
									<span>Export deliveries detail</span>
								</v-tooltip>
							</div>
					</v-col>
				</v-row>
			</div>

			<v-data-table
				:items="listData"
				:page.sync="page"
				:headers="header"
				:items-per-page="itemsPerPage"
				:search="search"
				hide-default-footer
				mobile-breakpoint="1080"
				no-data-text="No record found"
			>
				<template v-slot:item.pickup="{ item }">
					<div>{{ item.pickup === 'Y' ? 'Yes' : 'No' }}</div>
				</template>
				<template v-slot:item.status="{ item }">
					<div
						v-if="item.status === 'IP'"
						class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
					>In Process</div>
					<div
						v-else-if="item.status === 'DP'"
						class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
					>Dispatched</div>
					<div
						v-else-if="item.status === 'D'"
						class="text-tag-green text-tag-btn d-flex align-center justify-center my-2"
					>Delivered</div>
					<div
						v-else-if="item.status === 'R'"
						class="text-tag-red text-tag-btn d-flex align-center justify-center my-2"
					>Rejected</div>
				</template>
				<template v-slot:item.total_amount="{ item }">
					<div>{{ $options.filters.currency(item.total_amount) }}</div>
				</template>

				<template v-slot:item.action="{ item }">
					<v-tooltip left color="#757575">
						<template v-slot:activator="{ on }">
							<div v-on="on" class="my-pointer" v-on:click="deliveryView(item)">
								<img :src="$store.state.icons.icons['detail-view']" style="height:26px" class="table-icon" />
							</div>
						</template>
						<span>Delivery detail</span>
					</v-tooltip>
				</template>
			</v-data-table>

			<div class="d-flex align-center justify-center my-tabel-footer py-2">
				<v-pagination
					v-model="page"
					color="#2974FF"
					:total-visible="7"
					:length="getTotalPage(this.$store.state.delivery.delivery_data)"
				></v-pagination>
			</div>
		</v-row>
		<app-delivery-view ref="deliveryview"></app-delivery-view>
	</div>
</template>

<script>
import { ExportToCsv } from "export-to-csv";
import { deliveryExport } from "../../services/deliveryservice";
export default {
	components: {
		AppDeliveryView: () => import("../common/DeliveryView"),
		AppBackHeader: () => import("./Header")
	},

	data() {
		return {
			export_title:'',
			page: 1,
			itemsPerPage: 10,
			name: "",
			search: "",
			delivery_data: [],
			header: [
				{ text: "Delivery Id", value: "delivery_id" },
				{ text: "Company Name", value: "company_name" },
				{ text: "Distance (miles)", value: "distance" },
				{ text: "Delivery Date", value: "date_of_delivery" },
				{ text: "Delivery Time", value: "time_of_delivery" },
				{ text: "Pickup", value: "pickup" },
				{ text: "Total Amount", value: "total_amount" },
				{ text: "Status", value: "status" },
				{
					text: "Action",
					value: "action",
					sortable: false,
					align: "center"
				}
			]
		};
	},

	computed: {
		listData() {
			let data = this.$store.getters.getDelivery;
			if (this.$router.currentRoute.matched[1].path === "/contractors") {
				if(data.length === 0){
					let id = null;
					id = parseInt(this.$route.params.id);
					if(id !== null){
						this.$store.dispatch("deliveryOfCont",id);
						data = this.$store.getters.getList;	
					}
				}
			} else if (this.$router.currentRoute.matched[1].path === "/distributors") {
				if(data.length === 0){
					let id = null;
					id = parseInt(this.$route.params.id);
					if(id !== null){
						this.$store.dispatch("deliveryOfDist",id);
						data = this.$store.getters.getList;	
					}
				}
			}
			return data;
		},

		titleName() {
			let id = null;
			id = parseInt(this.$route.params.id);
			if (this.$router.currentRoute.matched[1].path === "/contractors") {
				if (id !== null) {
					let data = JSON.parse(
						JSON.stringify(this.$store.getters.getContractorById(id))
					);
					if(data === true) {
						this.$store.dispatch("contList");
						data = JSON.parse(
							JSON.stringify(this.$store.getters.getContractorById(id))
						);	
					}
					this.name = data.fullname;
				}
			} else if (
				this.$router.currentRoute.matched[1].path === "/distributors"
			) {
				if (id !== null) {
					let data = JSON.parse(
						JSON.stringify(this.$store.getters.getDistributorByCompanyId(id))
					);
					if(data === true) {
						this.$store.dispatch("distList");
						data = JSON.parse(
							JSON.stringify(this.$store.getters.getDistributorByCompanyId(id))
						);	
					}
					this.name = data.fullname;
				}
			}

			return this.name;
		}
	},

	methods: {
		getTotalPage(data) {
			return Math.ceil(data.length / this.itemsPerPage);
		},

		deliveryView(item) {
			this.$refs.deliveryview.openDeliveryView(item.delivery_id);
		},
		
		deliveryExport() {
			if (this.$router.currentRoute.matched[1].path === "/contractors") {
				this.export_title = 'Contractor delivery'
      			this.delivery_data = this.$store.getters.getDelivery;
			}else if (this.$router.currentRoute.matched[1].path === "/distributors") {
				this.export_title = 'Distributor delivery'
				this.delivery_data = this.$store.getters.getDelivery;
			}
      const options = {
        filename: this.export_title,
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: this.export_title,
        useTextFile: false,
        useBom: true,
        // useKeysAsHeaders: true,
        headers: [
			"Delivery_Id",
			"Company_Name",
			"Distance(miles)",
			"Delivery_Date",
			"Delivery_Time",
			"Pickup",
			"Total_Amount",
			"Status",
        ],
      };

      const csvExporter = new ExportToCsv(options);

      let delivery_ob = [];
      this.delivery_data.forEach((data) => {
        let delivery = {};
		delivery.delivery_id = data.delivery_id;
        delivery.company_name = data.company_name;
        delivery.distance = data.distance;
        delivery.date_of_delivery = data.date_of_delivery;
        delivery.time_of_delivery = data.time_of_delivery;
        delivery.pickup = data.pickup;
        delivery.total_amount = data.total_amount;
        if (data.status === "IP") {
          delivery.status = "In Process";
        } else if (data.status === "DP") {
          delivery.status = "Dispatched";
        } else if (data.status === "D") {
          delivery.status = "Delivered";
        }else if (data.status === "R") {
          delivery.status = "Rejected";
		}
        delivery_ob.push(delivery);
      });
      csvExporter;
      csvExporter.generateCsv(delivery_ob);
    },
	}
};
</script>
